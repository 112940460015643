import { MenuEntry } from '@encodix/smexuikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://egonswap.app/',
  },
  {
    label: 'Swap',
    icon: 'HomeIcon',
    href: '/swap',
  },
  {
    label: 'Liquidity',
    icon: 'HomeIcon',
    href: '/pool',
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://egonswap.app/#/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://egonswap.app/#/pools',
  },
  // {
  //   label: 'Analytics',
  //   icon: 'InfoIcon',
  //   href: 'https://info.egonswap.app',
  // },
  // {
  //   label: 'Audit',
  //   icon: 'InfoIcon',
  //   href: 'https://egonswap.app',
  // },
]

export default config
